import { c, h } from '@debbie/cortex/dist'
import { RootState } from '@debbie/cortex/dist/reducer'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useMe } from '@debbie/cortex/dist/me'
import {
  Button,
  Errors,
  Loading,
  Modal,
  Panel,
  Timestamp,
} from '@debbie/iris/dist/components'
import TopNav from './TopNav'
import THEME from '@debbie/iris/dist/theme'
import RemoteTable from '@debbie/iris/dist/components/RemoteTable'
import {
  tribunalJobEntity,
  useTribunalCaseJobs,
  useTribunalCasesByCaseId,
} from '@debbie/cortex/dist/tribunal'
import {
  CourtMeetingForm,
  CourtMeetingState,
  CourtMeetingTribunalJob,
  InternationalTribunalJobType,
  MeetingType,
  meetingTypes,
  TribunalJobsFilter,
  TribunalJobType,
  TribunalListJob,
} from '@debbie/common/dist/tribunal/jobs/types'
import { courts } from '@debbie/common/dist/court'
import { CourtMeetingForm as CourtMeetingFormComponent } from '@debbie/iris/dist/components/tribunal/jobs/Jobs/CourtMeeting'
import { Check, Crosshair, X } from '@debbie/iris/dist/components/Icons'
import { useMutation } from '@debbie/common/dist/util/hooks'
import keys from '@debbie/common/dist/cache/keys'
import * as tribunalAPI from '@debbie/cortex/dist/api/tribunal'
import { ID } from '@debbie/common/dist'
import q from '@debbie/cortex/dist/query'
import { notification } from '@debbie/cortex/dist/notifications'

type Props = {}

type TransformedTribunalListJob = TribunalListJob & {
  type: InternationalTribunalJobType.CourtMeeting
} & {
  meetingDate: string
  meetingForm: CourtMeetingForm | null
  meetingLocation: string | null | undefined
  meetingType?: MeetingType
  state: CourtMeetingState | null | undefined
}

const LoggedInView = (props: Props) => {
  const me = useMe()

  useEffect(() => {
    if (h.hasData(me)) {
      c.owners.select(me.data.tenants[0].id)
    }
  }, [me.loadingState])

  const [selected, setSelected] = useState<TransformedTribunalListJob | null>(
    null,
  )

  return (
    <Background>
      <TopNav />
      <Wrapper>
        <Panel>
          {h.hasData(me) ? (
            <RemoteTable<TransformedTribunalListJob, TribunalJobsFilter>
              useFetch={(filters, page, pageSize) => {
                const jobs = useTribunalCaseJobs(
                  {
                    ...filters,
                    types: [TribunalJobType.CourtMeeting],
                    assignee: me.data.id,
                  },
                  page,
                  pageSize,
                )

                return h.hasData(jobs)
                  ? {
                      ...jobs,
                      data: {
                        ...jobs.data,
                        items: jobs.data.items.map((item) => {
                          if (item.type !== TribunalJobType.CourtMeeting) {
                            throw new Error('Not court meeting')
                          }
                          return {
                            ...item,
                            meetingDate: item.data.meetingDate,
                            meetingForm: item.data.meetingForm,
                            meetingLocation: item.data.meetingLocation,
                            meetingType: meetingTypes.find(
                              (t) => t.id === item.data.meetingTypeId,
                            ),
                            state: item.data.state,
                          }
                        }),
                      },
                    }
                  : jobs
              }}
              initFilters={{}}
              sortKeyKey="sortKey"
              sortDirectionKey="sortDirection"
              values={{
                caseSequentialId: {
                  label: 'Sagsid',
                  sortable: false,
                },
                meetingDate: {
                  label: 'Tidspunkt',
                  map: (date) =>
                    date ? <Timestamp showTime date={date} /> : '',
                },
                data: {
                  label: 'Sted',
                  sortable: false,
                  map: (data) => {
                    const court = courts.find((c) => c.courtId === data.courtId)
                    if (court == null) {
                      return data.courtId ?? ''
                    }
                    return `${court.name}${
                      data.meetingLocation ? ` (${data.meetingLocation})` : ''
                    }`
                  },
                },
                caseReference: {
                  label: 'Rettens reference',
                  sortable: false,
                },
                meetingType: {
                  label: 'Mødetype',
                  sortable: false,
                  map: (meetingType) =>
                    meetingType != null ? meetingType.label : '',
                },
                state: {
                  label: 'Status',
                  map: (state) => (state ? statusNames[state] : 'Ingen status'),
                  sortable: false,
                },
                meetingForm: {
                  label: 'Handlinger',
                  map: (form, job) => {
                    return (
                      <StateDependent job={job} setSelected={setSelected} />
                    )
                  },
                  sortable: false,
                },
              }}
              genKey={(j) => j.id}
              pageSize={100}
            />
          ) : null}
        </Panel>
        {selected ? (
          <Modal
            onToggle={() => {
              setSelected(null)
            }}
          >
            <MeetingForm job={selected} setSelected={setSelected} />
          </Modal>
        ) : null}
      </Wrapper>
    </Background>
  )
}

const statusNames: { [s in CourtMeetingState]: string } = {
  [CourtMeetingState.Accepted]: 'Du har accepteret',
  [CourtMeetingState.Concluded]: 'Færdig',
  [CourtMeetingState.NeedsApproval]: 'Afventer accept',
  [CourtMeetingState.NeedsRevision]: 'Kræver ændringer',
  [CourtMeetingState.NotesSent]: 'Noter indsendt',
  [CourtMeetingState.ReadyForMeeting]: 'Klar til møde',
  [CourtMeetingState.Rejected]: 'Du har afvist',
  [CourtMeetingState.Canceled]: 'Aflyst',
}

const StateDependent = (props: {
  job: TransformedTribunalListJob
  setSelected: (job: TransformedTribunalListJob | null) => void
}) => {
  const job = props.job
  const form = job.data.meetingForm

  const stateSubmitter = useMutation(
    (id: ID, data) => {
      return tribunalAPI.patchJob(id, { data: data })
    },
    {
      onSuccess: (res, id) => {
        q.invalidate(keys.write.modify(tribunalJobEntity, id))
      },
    },
  )

  if (
    job.data.state === CourtMeetingState.Accepted ||
    job.data.state === CourtMeetingState.ReadyForMeeting ||
    job.data.state === CourtMeetingState.NeedsRevision
  ) {
    return (
      <ButtonGroup>
        <Button
          size="small"
          theme={
            form != null && form.questions != null ? 'secondary' : undefined
          }
          onClick={() => {
            props.setSelected(job)
          }}
        >
          {form != null && form.questions != null ? 'Rediger' : 'Udfyld'}
        </Button>
        {job.data.state === CourtMeetingState.ReadyForMeeting ? (
          <Button
            loading={stateSubmitter.isLoading}
            size="small"
            theme="secondary"
            onClick={() => {
              stateSubmitter.mutate(job.id, {
                ...job.data,
                state: CourtMeetingState.Rejected,
              })
            }}
          >
            <X />
          </Button>
        ) : null}
      </ButtonGroup>
    )
  }
  if (job.data.state == null) {
    return (
      <Button
        loading={stateSubmitter.isLoading}
        size="small"
        theme="secondary"
        onClick={() => {
          stateSubmitter.mutate(job.id, {
            ...job.data,
            state: CourtMeetingState.Rejected,
          })
        }}
      >
        <X />
      </Button>
    )
  }

  if (job.data.state === CourtMeetingState.NeedsApproval) {
    return (
      <ButtonGroup>
        <Button
          loading={stateSubmitter.isLoading}
          size="small"
          onClick={() => {
            stateSubmitter.mutate(job.id, {
              ...job.data,
              state: CourtMeetingState.Accepted,
            })
          }}
        >
          <Check />
        </Button>
        <Button
          loading={stateSubmitter.isLoading}
          size="small"
          theme="secondary"
          onClick={() => {
            stateSubmitter.mutate(job.id, {
              ...job.data,
              state: CourtMeetingState.Rejected,
            })
          }}
        >
          <X />
        </Button>
      </ButtonGroup>
    )
  }

  return <></>
}

const MeetingForm = (props: {
  job: TransformedTribunalListJob
  setSelected: (job: TransformedTribunalListJob | null) => void
}) => {
  const tribunalCase = useTribunalCasesByCaseId(props.job.caseId)

  if (h.isError(tribunalCase)) {
    if (tribunalCase.error instanceof Error) {
      return <Errors errors={[tribunalCase.error.message]} />
    }
    return <p>Fejl</p>
  }

  if (h.hasData(tribunalCase)) {
    if (tribunalCase.data == null) {
      return <p>Fejl</p>
    }

    const tCase = tribunalCase.data.find(
      (tc) => tc.id === props.job.tribunalCaseId,
    )
    if (tCase == null) {
      return <p>Fejl</p>
    }

    return (
      <>
        <CourtMeetingFormComponent
          job={props.job}
          tribunalCase={tCase}
          closeForm={() => props.setSelected(null)}
          viewer="receiver"
        />
      </>
    )
  }

  return <Loading />
}

const Background = styled.div`
  background: ${THEME.BACKGROUND};
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`

const Wrapper = styled.div`
  padding: 1em;
  margin: 0px auto;
  max-width: 1480px;
  width: 100%;
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 0.5em;
`

export default LoggedInView
