import 'react-datepicker/dist/react-datepicker.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import * as Sentry from '@sentry/browser'
import moment from 'moment'
import React from 'react'
import { hotjar } from 'react-hotjar'
import NotificationSystem from 'react-notification-system'
import { Route } from 'react-router-dom'
import styled from 'styled-components'

import { h, Provider } from '@debbie/cortex/dist'
import { notifications } from '@debbie/cortex/dist'
import X from '@debbie/iris/dist/components/X'
import Authenticate from '@debbie/iris/dist/components/Authenticate'

import logo from './assets/images/logoneg.svg'
import ExperianClientNumber from './components/ExperianClientNumber'
import CollectorDNSSetup from './components/CollectorDNSSetup'
import {
  HOTJAR_SNIPPET_VERSION,
  HOTJAR_PROJECT_ID,
  ENV,
  SENTRY_DSN,
  ENABLE_SENTRY,
} from './config'
import LoggedInView from './LoggedInView'
import MeetingLoggedInView from './bespoke/mortang/LoggedInView'
import { useMe } from '@debbie/cortex/dist/me'
import { PlaceholderLoader } from '@debbie/iris/dist/components'

if (ENABLE_SENTRY && SENTRY_DSN) Sentry.init({ dsn: SENTRY_DSN })

moment.locale()

const Wrapper = styled.div``

if (ENV !== 'development' && HOTJAR_PROJECT_ID && HOTJAR_SNIPPET_VERSION) {
  hotjar.initialize(
    parseInt(HOTJAR_PROJECT_ID),
    parseInt(HOTJAR_SNIPPET_VERSION),
  )
}

const App = () => {
  return (
    <>
      <Wrapper>
        <Provider>
          <X
            logo={logo}
            loggedInView={
              <Authenticate>
                <LoginRouting />
              </Authenticate>
            }
            routes={[
              <Route key="experian" path="/experian/:creditorId">
                <ExperianClientNumber />
              </Route>,
              <Route key="dns" path="/dns-setup/:tenantId/:collectorId">
                <CollectorDNSSetup />
              </Route>,
            ]}
          />
        </Provider>
      </Wrapper>

      <NotificationSystem
        ref={(r) => notifications.attachNotificationHandler(r)}
      />
    </>
  )
}

const LoginRouting = (props: {}) => {
  const me = useMe()

  if (h.isError(me)) {
    return <p>Error</p>
  }

  if (!h.hasData(me) || h.isError(me)) {
    return <PlaceholderLoader />
  }

  return h.hasData(me) &&
    me.data.details.meetingAccountType === 'meeting_only' ? (
    <MeetingLoggedInView />
  ) : (
    <LoggedInView />
  )
}

export default App
