import { c, h } from '@debbie/cortex/dist'
import { RootState } from '@debbie/cortex/dist/reducer'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useMe } from '@debbie/cortex/dist/me'
import THEME from '@debbie/iris/dist/theme'

type Props = {}

const TopNav = (props: Props) => {
  return (
    <>
      <Wrapper>
        <div>Møder</div>
        <Logout
          onClick={() => {
            c.auth.logout()
          }}
        >
          Log ud
        </Logout>
      </Wrapper>
      <Stub />
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 1em;
  background: #fff;
  z-index: 16;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
`

const Stub = styled.div`
  height: 50px;
`

const Logout = styled.div`
  cursor: pointer;
`

export default TopNav
